    @import "aos/dist/aos.css";
    @import "css/style.css";


    #preloader {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100%;
        z-index: 99999;
        display: flex;
    }

    #preloader:before,
    #preloader:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        height: 100%;
        z-index: -1;
        background-color: #000;
        -webkit-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
    }

    #preloader:after {
        left: auto;
        right: 0;
    }

    #preloader .loader_line {
        margin: auto;
        width: 1px;
        height: 250px;
        position: relative;
        overflow: hidden;
        -webkit-transition: all 0.8s ease 0s;
        -o-transition: all 0.8s ease 0s;
        transition: all 0.8s ease 0s;
    }

    .loader_line:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 1px;
        height: 0%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        background-color: #fff;
        -webkit-animation: lineheight 1000ms ease-in-out 0s forwards;
        -o-animation: lineheight 1000ms ease-in-out 0s forwards;
        animation: lineheight 1000ms ease-in-out 0s forwards;
    }

    .loader_line:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #999999;
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
        -webkit-animation: lineround 1200ms linear 0s infinite;
        -o-animation: lineround 1200ms linear 0s infinite;
        animation: lineround 1200ms linear 0s infinite;
        animation-delay: 2000ms;
    }

    @keyframes lineheight {
        0% {
            height: 0%;
        }

        100% {
            height: 100%;
        }
    }

    @keyframes lineround {
        0% {
            -webkit-transform: translateY(-100%);
            -ms-transform: translateY(-100%);
            -o-transform: translateY(-100%);
            transform: translateY(-100%);
        }

        100% {
            -webkit-transform: translateY(200%);
            -ms-transform: translateY(200%);
            -o-transform: translateY(200%);
            transform: translateY(200%);
        }
    }

    /*hiding all*/
    .preloaded .loader_line:after {
        opacity: 0;
    }

    .preloaded .loader_line {
        opacity: 0;
        height: 100% !important;
    }

    .preloaded:before,
    .preloaded:after {
        -webkit-animation: preloadedzero 300ms ease-in-out 500ms forwards;
        -o-animation: preloadedzero 300ms ease-in-out 500ms forwards;
        animation: preloadedzero 300ms ease-in-out 500ms forwards;
    }

    @keyframes preloadedzero {
        0% {
            width: 50%;
        }

        100% {
            width: 0%;
        }
    }

    input[type=number] {
        display: inline-block;
        width: 50%;
        text-align: center;
        font: 3rem 'Fira Sans', sans-serif;
        display: block;
        margin-right: auto;
        margin-left: auto;
    }

    .mintSize {
        position: relative;
        width: 170px;
        overflow: hidden;
        background-color: #096f74;
        -webkit-border: 1px solid transparent;
        -moz-border: 1px solid transparent;
        -ms-border: 1px solid transparent;
        -o-border: 1px solid transparent;
        border: 1px solid transparent;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        -ms-border-radius: 2px;
        -o-border-radius: 2px;
        border-radius: 2px;
        background-color: #23162c;
        color: #fff;
        padding: 5px 5px;
    }

    .mintSize input::placeholder {
        color: #fff;
    }

    .mintSize input:focus-within {
        outline: none;
        border-color: rgba(171, 255, 135, 0.8);
    }